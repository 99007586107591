import Vue from "vue";
import {
  Button,
  Icon,
  Popup,
  Search,
  Uploader,
  Field,
  Toast,
  Loading,
  Col,
  Row,
  Dialog,
  Stepper,
  Switch
} from "vant";
Vue.use(Button);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Search);
Vue.use(Uploader);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Loading);
Vue.use(Col);
Vue.use(Row);
Vue.use(Dialog);
Vue.use(Stepper);
Vue.use(Switch);
