import Vue from "vue";
import {
    Button,
    Icon,
    Popup,
    Search,
    Field,
    DropdownMenu,
    DropdownItem,
    Uploader,
    Checkbox,
    CheckboxGroup,
    Toast,
    Dialog,
    Overlay,
    Loading,
    Image as VanImage
} from "vant";
Vue.use(Button);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Search);
Vue.use(Field);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Uploader);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(VanImage);